<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary ">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#aaa">{{ getTitle() }}</a>
    </div>
  </div>
</nav>
<div class="wrapper wrapper-full-page ">
  <div class="full-page section-image" filter-color="black">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>