import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let cloneRequest = request;

    let user: any = localStorage.getItem("user");
    user = JSON.parse(user);

    if (user?.token) {
      cloneRequest = request.clone({
        headers: request.headers
          .set("authorization", `Bearer ${user.token}`)
          .set("Accept-Language", "es"),
      });
    }

    if (request.url.indexOf("/auth") != -1) {
      cloneRequest = request.clone({
        headers: request.headers
          .set("authorization", `Bearer login`)
          .set("Accept-Language", "es"),
      });
    }

    return next.handle(cloneRequest);
  }
}
