import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'awb',
        loadChildren: () => import('./modules/awb/awb.module').then((x) => x.AwbModule),
      },
      {
        path: 'truck/travel',
        loadChildren: () =>
          import('./modules/truck-travel/truck-travel.module').then(
            (x) => x.TruckTravelModule
          ),
      },
      {
        path: 'last/mile',
        loadChildren: () =>
          import('./modules/last-mile/last-mile.module').then((x) => x.LastMileModule),
      },
      {
        path: 'maintainers',
        loadChildren: () =>
          import('./modules/maintainers/maintainers.module').then(
            (x) => x.MaintainersModule
          ),
      },
      {
        path: 'ddu',
        loadChildren: () => import('./modules/ddu/ddu.module').then((x) => x.DduModule),
      },
      {
        path: 'help/center',
        loadChildren: () =>
          import('./modules/help-center/help-center.module').then(
            (x) => x.HelpCenterModule
          ),
      },
      {
        path: 'truck/travel/external',
        loadChildren: () =>
          import('./modules/truck-travel-external/truck-travel-external.module').then(
            (x) => x.TruckTravelExternalModule
          ),
        data: {
          allowedProfiles: ['Facility', 'Courier'],
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module').then((x) => x.ReportsModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((x) => x.AuthModule),
      },
    ],
  },
];
