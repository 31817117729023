export const environment = {
  production: false,

  API_DOMAINS: {
    // ----- SERVER PROD -----
    // LOGIN: 'https://www.serhafen-back-prod.com/auth',
    // USERS: 'https://www.serhafen-back-prod.com/users',
    // AWB: 'https://www.serhafen-back-prod.com/awb/detail',
    // MAWB: 'https://www.serhafen-back-prod.com/awb',
    // AWB_HEADER: 'https://www.serhafen-back-prod.com/awb/header',
    // MASTER_FLIGHTS: 'https://www.serhafen-back-prod.com/flights',
    // TRUCK_TRAVEL: 'https://www.serhafen-back-prod.com/truck/travel',
    // TRUCKS: 'https://www.serhafen-back-prod.com/truck/travel/trucks',
    // COMPANY: 'https://www.serhafen-back-prod.com/truck/travel/companies',
    // TYPE_TRAVEL: 'https://www.serhafen-back-prod.com/truck/travel/type',
    // FLIGHTS: 'https://www.serhafen-back-prod.com/flights',
    // MAWB_DETAIL: 'https://www.serhafen-back-prod.com/mawb',
    // AWB_ROUTE: 'https://www.serhafen-back-prod.com/awb/detail/route',
    // FLOW: 'https://www.serhafen-back-prod.com/flow',
    // STATUS_CODES: 'https://www.serhafen-back-prod.com/status-code',
    // ENV: 'PROD',

   // ----- SERVER DEV -----
   LOGIN: 'https://serhafen-back-dev.com/auth',
   USERS: 'https://serhafen-back-dev.com/users',
   AWB: 'https://serhafen-back-dev.com/awb/detail',
   MAWB: 'https://serhafen-back-dev.com/awb',
   AWB_HEADER: 'https://serhafen-back-dev.com/awb/header',
   MASTER_FLIGHTS: 'https://serhafen-back-dev.com/flights',
   TRUCK_TRAVEL: 'https://serhafen-back-dev.com/truck/travel',
   TRUCKS: 'https://serhafen-back-dev.com/truck/travel/trucks',
   COMPANY: 'https://serhafen-back-dev.com/truck/travel/companies',
   TYPE_TRAVEL: 'https://serhafen-back-dev.com/truck/travel/type',
   FLIGHTS: 'https://serhafen-back-dev.com/flights',
   MAWB_DETAIL: 'https://serhafen-back-dev.com/mawb',
   AWB_ROUTE: 'https://serhafen-back-dev.com/awb/detail/route',
   FLOW: 'https://www.serhafen-back-dev.com/flow',
   STATUS_CODES: 'https://www.serhafen-back-dev.com/status-code',
   ENV: 'DEV',

   // ----- SERVER QA -----
   // LOGIN: 'https://serhafen-back-qa.com/auth',
   // USERS: 'https://serhafen-back-qa.com/users',
   // AWB: 'https://serhafen-back-qa.com/awb/detail',
   // MAWB: 'https://serhafen-back-qa.com/awb',
   // AWB_HEADER: 'https://serhafen-back-qa.com/awb/header',
   // MASTER_FLIGHTS: 'https://serhafen-back-qa.com/flights',
   // TRUCK_TRAVEL: 'https://serhafen-back-qa.com/truck/travel',
   // TRUCKS: 'https://serhafen-back-qa.com/truck/travel/trucks',
   // COMPANY: 'https://serhafen-back-qa.com/truck/travel/companies',
   // TYPE_TRAVEL: 'https://serhafen-back-qa.com/truck/travel/type',
   // FLIGHTS: 'https://serhafen-back-qa.com/flights',
   // MAWB_DETAIL: 'https://serhafen-back-qa.com/mawb',
   // AWB_ROUTE: 'https://serhafen-back-qa.com/awb/detail/route',
   // FLOW: 'https://www.serhafen-back-qa.com/flow',
   // STATUS_CODES: 'https://www.serhafen-back-qa.com/status-code',
   // ENV: 'QA',

    // ----- SERVER LOCAL -----
    // LOGIN: 'http://localhost:3000/auth',
    // USERS: 'http://localhost:3000/users',
    // AWB: 'http://localhost:3000/awb/detail',
    // MAWB: 'http://localhost:3000/awb',
    // AWB_HEADER: 'http://localhost:3000/awb/header',
    // MASTER_FLIGHTS: 'http://localhost:3000/flights',
    // TRUCK_TRAVEL: 'http://localhost:3000/truck/travel',
    // TRUCKS: 'http://localhost:3000/truck/travel/trucks',
    // COMPANY: 'http://localhost:3000/truck/travel/companies',
    // TYPE_TRAVEL: 'http://localhost:3000/truck/travel/type',
    // FLIGHTS: 'http://localhost:3000/flights',
    // MAWB_DETAIL: 'http://localhost:3000/mawb',
    // AWB_ROUTE: 'http://localhost:3000/awb/detail/route',
    // FLOW: 'http://localhost:3000/flow',
    // STATUS_CODES: 'http://localhost:3000/status-code',
    // ENV: 'LOCAL',
  },
  TYPE: 'pc',
};
